import React from 'react'
import './home.css';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

//images
import peacefulLivingLogo from "../../images/peacefulLivingLogo.png"
import luckycatImage from "../../images/LuckycatHomePageImage.jpg";
import duoTRXLogo from "../../images/Duotrx_logo.png";


const HomePage = () =>{
	const navigate = useNavigate();
	const [smallStyle, setSmallStyle] = useState(false);


	useEffect(()=>{

		window.addEventListener('resize', (event)=>{
			let screenWidth = document.documentElement.clientWidth;

			if (screenWidth <= 1200)
			{
				setSmallStyle(true)
			}
			else
			{
				setSmallStyle(false);
			}
		});

	}, [smallStyle])

	
	//On initial window load
	useEffect(()=>{
		
		let screenWidth = document.documentElement.clientWidth;

		if (screenWidth <= 1200)
		{
			setSmallStyle(true)
		}
		else
		{
			setSmallStyle(false);
		}

	}, [smallStyle])


	const GoToPage = (url) =>{
    	navigate(url);
    	window.scrollTo(0,0);
  	}

	return(
		<div className={!smallStyle ? "home" : "homeSmall"}>
			<div className={!smallStyle ? "PeacefulLivingTop" : "PeacefulLivingTopSmall"}>
				<div className={!smallStyle ? "PeacefulLivingTopLogoArea" : "PeacefulLivingTopLogoAreaSmall"}>
					<h1>ASURANSI JIWA GRATIS +</h1>
					<h1>BONUS UANG & UNDIAN</h1>
				</div>
			</div>

			<div className="PeacefulLivingInformationArea">
				<div className={!smallStyle ? "PeacefulLivingInformationAreaBlock" : "PeacefulLivingInformationAreaBlockSmall"}>
					<div className="PeacefulLivingInformationAreaBlockInner">
						<h2>Tentang Kami</h2>
						<p>Perlindungan Asuransi jiwa gratis.</p>
						<p>Premi Asuransi dapat teramat mahal sehingga banyak orang kesulitan dalam memilih antara bergabung dengan asuransi atau memenuhi kebutuhan hidup.</p>
						<p className="PeacefulLivingInformatinoAreaLink"><a href="/tentangKami">Lihat Lebih</a></p>
					</div>
				</div>

				<div className={!smallStyle ? "PeacefulLivingInformationAreaBlock PeacefulLivingInformationAreaLeftBorder PeacefulLivingInformationAreaRightBorder" : "PeacefulLivingInformationAreaBlockSmall"}>
					<div className="PeacefulLivingInformationAreaBlockInner">
						<h2>Perlindungan</h2>
						<p>Hanya Rp. 100.000 Nett ADMIN FEE untuk perlindungan dari 49 penyakit kritis selama 1 tahun.</p>
						<p>Tidak ada perlindugan yang lebih baik dari ini. Jangan tunggu, persiapkan diri dan keluarga anda dari hal-hal  ⁠yang tak terduga.</p>
						<p className="PeacefulLivingInformatinoAreaLink"><a href="/perlindungan">Lihat Lebih</a></p>
					</div>
				</div>

				<div className={!smallStyle ? "PeacefulLivingInformationAreaBlock" : "PeacefulLivingInformationAreaBlockSmall"}>
					<div className="PeacefulLivingInformationAreaBlockInner">
						<h2>Bonus Berbonus/Peluang Bisnis</h2>
						<p>Peaceful living bukan hanya memberikan polis asuransi, tapi juga peluang bisnis bagi anda. Dengan begitu banyak reward/imbalan yang dapat membantu anda mencapai kebebasan financial.</p>
						<p className="PeacefulLivingInformatinoAreaLink"><a href="/peluangBisnis">Lihat Lebih</a></p>
					</div>
				</div>
			</div>

			<div className="LuckyCatHomePageInfo">
				<img src={luckycatImage}/>
				<div className="LuckyCatHomePageInfoArea">
					<div className={!smallStyle ? "LuckyCatHomePageInfoBlock" : "LuckyCatHomePageInfoBlockSmall"}>
						<div className={!smallStyle ? "LuckyCatHomePageInfoBlockInner" : "LuckyCatHomePageInfoBlockInnerSmall"}>
							<h2>Hadiah Pertama</h2>
							<p>Rp. 3.200.000</p>
						</div>
					</div>
					<div className={!smallStyle ? "LuckyCatHomePageInfoBlock PeacefulLivingInformationAreaLeftBorder PeacefulLivingInformationAreaRightBorder" : "LuckyCatHomePageInfoBlockSmall"}>
						<div className={!smallStyle ? "LuckyCatHomePageInfoBlockInner" : "LuckyCatHomePageInfoBlockInnerSmall"}>
							<h2>Hadiah Kedua</h2>
							<p>Rp. 1.600.000</p>
						</div>
					</div>
					<div className={!smallStyle ? "LuckyCatHomePageInfoBlock" : "LuckyCatHomePageInfoBlockSmall"}>
						<div className={!smallStyle ? "LuckyCatHomePageInfoBlockInner" : "LuckyCatHomePageInfoBlockInnerSmall"}>
							<h2>Hadiah Ketiga</h2>
							<p>Rp. 3.200.000</p>
						</div>
					</div>
				</div>
			</div>

			<div className="ChevalierOfTradeHomePageInfoTop">
				<div className={!smallStyle ? "ChevalieOfTradeHomePageImageBlock" : "ChevalieOfTradeHomePageImageBlockSmall"}>
					<div className={!smallStyle ? "DuoTRXHomeImageLogo" : "DuoTRXHomeImageLogoSmall"}>
						<img src={duoTRXLogo}/>
					</div>
					<h1>Chevalier of Traders</h1>
				</div>

				<div className="ChevalierOfTradeHomeInfoArea">
					<div className={!smallStyle ? "ChevalierOfTradeHomeInfoBlock" : "ChevalierOfTradeHomeInfoBlockSmall"}>
						<div className={!smallStyle ? "ChevalierOfTradeHomeInfoBlockInner" : "ChevalierOfTradeHomeInfoBlockInnerSmall"}>
							<h2>Round 1: Baron of Traders</h2>
							<p>Score 5.000 CKU</p>
							<p>Hadiah Rp 8.000.000</p>
						</div>
					</div>

					<div className={!smallStyle ? "ChevalierOfTradeHomeInfoBlock PeacefulLivingInformationAreaLeftBorder PeacefulLivingInformationAreaRightBorder" : "ChevalierOfTradeHomeInfoBlockSmall"}>
						<div className={!smallStyle ? "ChevalierOfTradeHomeInfoBlockInner" : "ChevalierOfTradeHomeInfoBlockInnerSmall"}>
							<h2>Round 2: Marques of Traders</h2>
							<p>Score 40.000 CKU</p>
							<p>Hadiah Rp 80.000.000</p>
						</div>
					</div>

					<div className={!smallStyle ? "ChevalierOfTradeHomeInfoBlock" : "ChevalierOfTradeHomeInfoBlockSmall"}>
						<div className={!smallStyle ? "ChevalierOfTradeHomeInfoBlockInner" : "ChevalierOfTradeHomeInfoBlockInnerSmall"}>
							<h2>Round 3: King of Traders</h2>
							<p>Score 100.000 CKU</p>
							<p>Hadiah Rp 800.000.000</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default HomePage;