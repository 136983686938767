import React from 'react'
import './kontakKami.css';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

const KontakKami = () => {

	const [smallStyle, setSmallStyle] = useState(false);


	useEffect(()=>{

		window.addEventListener('resize', (event)=>{
			let screenWidth = document.documentElement.clientWidth;

			if (screenWidth <= 1200)
			{
				setSmallStyle(true)
			}
			else
			{
				setSmallStyle(false);
			}
		});

	}, [smallStyle])

	
	//On initial window load
	useEffect(()=>{
		
		let screenWidth = document.documentElement.clientWidth;

		if (screenWidth <= 1200)
		{
			setSmallStyle(true)
		}
		else
		{
			setSmallStyle(false);
		}

	}, [smallStyle])

	return(
		<div className={!smallStyle ? "caraMulaiTop" : "caraMulaiTopSmall"}>
			<div className={!smallStyle ? "caraMulaiCover" : "caraMulaiCoverSmall"}>
			</div>
			<div className={!smallStyle ? "CaraMulai" : "CaraMulaiSmall"}>
				<h1>Cara Mulai </h1>
				<p><b>Hubungi WhatsApp kami sekarang di :</b></p>
				<p><a href="https://wa.me/+6282118213985" target="_blank">+62 821 1821 3985</a></p>
				<br/>
				<p><b>E-Mail kami di :</b></p>
				<p><a href="mailto:peaceful-living@duotrx.com" target="_blank">peaceful-living@duotrx.com</a></p>
				<br/>
				<p><b>Alamat kami</b></p>
				<p>Allianz BSD</p>
				<p>Foresta Business Loft 1, unit 23</p>
				<p>Jln BSD raya utama</p>
				<p>Kec : Pagedangan</p>
				<p>Kabupaten Pagedangan,</p>
				<p>Tangerang, Banten 15339</p>
			</div>
		</div>
	)
}

export default KontakKami;