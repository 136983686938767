import React from 'react'
import './Navbar.css';
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router";

//images
import menuIcon from "../../images/BurgerMenu.svg";
import closeIcon from "../../images/closex.svg";
import logo from "../../images/peacefulLivingLogoSmall.png";


const NavBar = () =>{

  const [dropdown, setDropdown] = useState(false);
  const [useDropDownMenu, setUseDropDownMenu] = useState(false);
  const navigate = useNavigate();
  const [smallStyle, setSmallStyle] = useState(false);


  useEffect(()=>{

    window.addEventListener('resize', (event)=>{
      let screenWidth = document.documentElement.clientWidth;

      if (screenWidth <= 1200)
      {
        setSmallStyle(true)
      }
      else
      {
        setSmallStyle(false);
      }
    });

  }, [smallStyle])

  
  //On initial window load
  useEffect(()=>{
    
    let screenWidth = document.documentElement.clientWidth;

    if (screenWidth <= 1200)
    {
      setSmallStyle(true)
    }
    else
    {
      setSmallStyle(false);
    }

  }, [smallStyle])



const ShowHideDropDown = () =>{
    let show = !dropdown;

    console.log(`Showing Drop Down: ${show}`);

    setDropdown(show);
  }

  const GoToPage = (url) =>{
      navigate(url);
      setDropdown(false);
      window.scrollTo(0,0);
    }

  const GoToSection = (url, section) =>{
    navigate(url);
    setDropdown(false);

    setTimeout(()=>{

      let offsetVariable = 100;

      if (smallStyle)
      {
        offsetVariable = 50;
      }

      let posY = document.getElementById(section).offsetTop - offsetVariable;

      window.scrollTo(0, posY)

    }, 300);
  }

    useEffect(()=>{
    //console.log(`Dropdown state : ${dropdown}`)

    if (dropdown)
    {
      document.addEventListener('mousedown', (event)=>{
        //console.log(event.srcElement.id);
        if (event.srcElement.id !== "NavDropDown")
        {
          setDropdown(false)
        }
      });
    }

  }, [dropdown]);

  useEffect(()=>{

    window.addEventListener('resize', (event)=>{
      let screenWidth = document.documentElement.clientWidth;

      //console.log(screenWidth);

      if (screenWidth <= 1200)
      {
        setUseDropDownMenu(true)
      }
      else
      {
        setUseDropDownMenu(false);
      }
    });

  }, [useDropDownMenu])

  
  //On initial window load
  useEffect(()=>{
    
    let screenWidth = document.documentElement.clientWidth;

    //console.log(screenWidth);

    if (screenWidth <= 1200)
    {
      setUseDropDownMenu(true)
    }
    else
    {
      setUseDropDownMenu(false);
    }

  }, [useDropDownMenu])

    const NavDropDown = ()=>{
    return (
      <div className="NavbarDropDown">
        <img className="menuIcon" src={dropdown ? closeIcon : menuIcon} onClick={() => {
          setDropdown((prev) => !prev)
        }}/>
        <nav className={`HideDropDownNav ${dropdown ? "DropDownNav" : ""}`} id="NavDropDown">
          <ul>
            <li onClick={()=>{GoToPage("/")}} id="NavDropDown"><label id="NavDropDown">Home</label></li>
            <li onClick={()=>{GoToPage("/tentangKami")}} id="NavDropDown"><label id="NavDropDown">Tentang Kami</label></li>
            <li onClick={()=>{GoToPage("/perlindungan")}} id="NavDropDown"><label id="NavDropDown">Perlindungan</label></li>
            <li onClick={()=>{GoToPage("/undianBerhadiah")}} id="NavDropDown"><label id="NavDropDown">Undian Berhadiah</label></li>
            <li onClick={()=>{GoToPage("/peluangBisnis")}} id="NavDropDown"><label id="NavDropDown">Bonus Berbonus</label></li>
            <li onClick={()=>{GoToPage("/kontakKami")}} id="NavDropDown"><label id="NavDropDown">Kontak Kami</label></li>
          </ul>
        </nav>
        <div className="PeacefulLivingNavLogoSmall" onClick={()=>{GoToPage("/")}}><img src={logo}/> Peaceful Living</div>
      </div>
    )
  }

  return(
    <div>
    { !useDropDownMenu ?
      <div className="navbar">
        <nav className="navcontrols">
          <ul>
            <li onClick={()=>{GoToPage("/")}}><div className="PeacefulLivingNavLogo"><img src={logo}/> Peaceful Living</div></li>
            <li onClick={()=>{GoToPage("/tentangKami")}}>Tentang Kami</li>
            <li onClick={()=>{GoToPage("/perlindungan")}}>Perlindungan</li>
            <li onClick={()=>{GoToPage("/undianBerhadiah")}}>Undian Berhadiah</li>
            <li onClick={()=>{GoToPage("/peluangBisnis")}}>Bonus Berbonus</li>
            <li onClick={()=>{GoToPage("/kontakKami")}}>Kontak Kami</li>
                     
            {/*<li onClick={()=>{GoToPage("/login")}}>Login</li>*/}
            {/*<li onClick={()=>{GoToPage("/signup")}}>Daftar</li>*/}
          </ul>
        </nav>
      </div>  
      :
      <NavDropDown/>
    }
    </div>  
    
  )
}


export default NavBar;