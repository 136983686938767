import React from 'react'
import './perlindungan.css';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

const Perlindungan = () => {
	const [smallStyle, setSmallStyle] = useState(false);


	useEffect(()=>{

		window.addEventListener('resize', (event)=>{
			let screenWidth = document.documentElement.clientWidth;

			if (screenWidth <= 1200)
			{
				setSmallStyle(true)
			}
			else
			{
				setSmallStyle(false);
			}
		});

	}, [smallStyle])

	
	//On initial window load
	useEffect(()=>{
		
		let screenWidth = document.documentElement.clientWidth;

		if (screenWidth <= 1200)
		{
			setSmallStyle(true)
		}
		else
		{
			setSmallStyle(false);
		}

	}, [smallStyle])

	return (
		<div className={!smallStyle ? "PerlindunganTop" : "PerlindunganTopSmall"}>
			<div className={!smallStyle ? "PerlindunganCover" : "PerlindunganCoverSmall"}>
			</div>
			<div className={!smallStyle ? "WhatDoWeCover" : "WhatDoWeCoverSmall"} id="WhatWeCover">
				<h1>Perlindungan</h1>

				<div className={!smallStyle ? "WhatDoWeCoverList" : "WhatDoWeCoverListSmall"}>
					<p>Yang ditanggung oleh Peaceful Living :</p>
					<ol>
						<li>Serangan Jantung</li>
						<li>Stroke</li>
						<li>Operasi Jantung Koroner</li>
						<li>Operasi Penggantian Katup Jantung</li>
						<li>Kanker</li>
						<li>Gagal Ginjal</li>
						<li>Kelumpuhan</li>
						<li>Multiple Sclerosis</li>
						<li>Tranplantasi Organ</li>
						<li>Penyakit Alzheimer</li>
						<li>Koma</li>
						<li>Penyakit Perkinson</li>
						<li>Terminal Illness</li>
						<li>Penyakit Paru-Paru Kronis</li>
						<li>Penyakit hati Kronis</li>
						<li>Penyakit Motor Neuron</li>
						<li>Musculary Dystrophy</li>
						<li>Anemia Aplastis</li>
						<li>Operasi Pembuluh Aorta</li>
						<li>Pulmonary Arterial Hypertension Primer</li>
						<li> Meningitis Bakteri</li>
						<li>Hepatitis Fulminant</li>
						<li>Tumor Otak Jinak</li>
						<li>Radang Otak</li>
						<li>Luka Bakar</li>
						<li>Poliomyelitis</li>
						<li>Trauma Kepala Serius</li>
					</ol>
					<ol start="28">
						<li>Apallic Syndrome</li>
						<li>Penyakit Jantung Koroner Lain Yang Serius</li>
						<li>Lupus Eritematosus Sistemik</li>
						<li>HIV Yang Didapatkan Melalui Transfusi Darah dan Pekerjaan</li>
						<li>Tuli (Hilangnya Fungsi Indra Pendegaran)</li>
						<li>Bisu (Kehilangan Kemampuan Bicara)</li>
						<li>Kebutaan</li>
						<li>Skleroderma Progresif</li>
						<li>Penyakit Kista Medullary</li>
						<li>Cardiomyopathy</li>
						<li>Aneriurisma Pembulan Darah Otak Yang Mensyaratkan Pembedahan</li>
						<li>Terputusnya Akar-Akar Syaraf Plexus Brachialis</li>
						<li>Stroke Yang Memerlukan Operasi Arteri Carotid</li>
						<li>Operasi Scoliosis Idiopatik</li>
						<li>Pankreatitis Menahun Yang Berulang</li>
						<li>Penyakit kaki Gajah Kronis</li>
						<li>Hilangnya Kemadirian Hidup</li>
						<li>Kematian Selaput Otot Atau Jaringan (Gangrene)</li>
						<li>Rheumatoid Arthritis Berat</li>
						<li>Colitis Ulcerative Berat (Cronh’s Disease)</li>
						<li>Penyakit Kawasaki Yang Mengakibatkan Komplikasi Pada Jantung</li>
						<li>Manfaat Angioplasti Dan Tindakan Ivasif Lainnya Untuk Penyakit Jantung Koroner</li>
					</ol>
				</div>

				<div className={!smallStyle ? "DependingOnAgeAndSex" : "DependingOnAgeAndSexSmall"} id="PayoutTable">
					<p>Tergantung pada usia dan jenis kelamin Anda, berikut adalah santunan yang kami berikan:</p>

					<div className="AgeSexPayoutTable">
						<div className={!smallStyle ? "AgeSexPayoutTableHeader" : "AgeSexPayoutTableHeaderSmall"}>
							<label>Usia</label>
							<label className="maleColumn">Pria<br/><label className={!smallStyle ? "DependingOnAgeSexSubHeader" : "DependingOnAgeSexSubHeaderSmall"}>Santunan Uang Pertanggungan</label></label>
							<label className="femaleColumn">Wanita<br/><label className={!smallStyle ? "DependingOnAgeSexSubHeader" : "DependingOnAgeSexSubHeaderSmall"}>Santunan Uang Pertanggungan</label></label>
						</div>
						<div className={!smallStyle ? "AgeSexPayoutTableRow oddRow" : "AgeSexPayoutTableRowSmall oddRow"}>
							<label>18-24</label>
							<label className="maleColumn">Rp. 910.000.000</label>
							<label className="femaleColumn">Rp. 1.100.000.000</label>
						</div>
						<div className={!smallStyle ? "AgeSexPayoutTableRow" : "AgeSexPayoutTableRowSmall"}>
							<label>25-29</label>
							<label className="maleColumn">Rp. 800.000.000</label>
							<label className="femaleColumn">Rp. 930.000.000</label>
						</div>
						<div className={!smallStyle ? "AgeSexPayoutTableRow oddRow" : "AgeSexPayoutTableRowSmall oddRow"}>
							<label>30-34</label>
							<label className="maleColumn">Rp. 700.000.000</label>
							<label className="femaleColumn">Rp. 760.000.000</label>
						</div>
						<div className={!smallStyle ? "AgeSexPayoutTableRow" : "AgeSexPayoutTableRowSmall"}>
							<label>35-39</label>
							<label className="maleColumn">Rp. 545.000.000</label>
							<label className="femaleColumn">Rp. 570.000.000</label>
						</div>
						<div className={!smallStyle ? "AgeSexPayoutTableRow oddRow" : "AgeSexPayoutTableRowSmall oddRow"}>
							<label>40-44</label>
							<label className="maleColumn">Rp. 380.500.000</label>
							<label className="femaleColumn">Rp. 440.000.000</label>
						</div>
						<div className={!smallStyle ? "AgeSexPayoutTableRow" : "AgeSexPayoutTableRowSmall"}>
							<label>45-49</label>
							<label className="maleColumn">Rp. 260.000.000</label>
							<label className="femaleColumn">Rp. 350.000.000</label>
						</div>
						<div className={!smallStyle ? "AgeSexPayoutTableRow oddRow" : "AgeSexPayoutTableRowSmall oddRow"}>
							<label>50-54</label>
							<label className="maleColumn">Rp. 170.000.000</label>
							<label className="femaleColumn">Rp. 250.000.000</label>
						</div>
						<div className={!smallStyle ? "AgeSexPayoutTableRow" : "AgeSexPayoutTableRowSmall"}>
							<label>55-58</label>
							<label className="maleColumn">Rp. 115.000.000</label>
							<label className="femaleColumn">Rp. 170.000.000</label>
						</div>
						<div className={!smallStyle ? "AgeSexPayoutTableRow oddRow" : "AgeSexPayoutTableRowSmall oddRow"}>
							<label>59</label>
							<label className="maleColumn">Rp. 95.000.000</label>
							<label className="femaleColumn">Rp. 130.000.000</label>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Perlindungan;