import { Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import Aos from 'aos';
import { store } from './store';
import { useEffect } from 'react';
import { getUSDPrice } from './actions/common';
import { jakartaCurrentTime } from './utils/date';
import "./App.css";

//Components
import NavBar from "./components/Navbar/Navbar";
import Footer from "./components/footer/footer";

//Pages
import LoginPage from './pages/Login';
import HomePage from './pages/home/home';
import TentangKami from './pages/tentangKami/tentangKami';
import Perlindungan from './pages/perlindungan/perlindungan';
import PeluangBisnis from './pages/peluangBisnis/peluangBisnis';
import UndianBerhadiah from './pages/undianBerhadiah/undianBerhadiah';
import KontakKami from './pages/kontakKami/kontakKami';
import RegisterPage from './pages/Register';


function App() {
  useEffect(() => {
    Aos.init();
    store.dispatch(getUSDPrice()).then((res) => {
      const { payload } = res;
      if (payload.current_date && payload.duration) {
        const date = new Date(payload.current_date);
        jakartaCurrentTime(
          payload.duration >= 1
            ? date.setSeconds(date.getSeconds() + Math.floor(payload.duration))
            : date
        );
      }
    });
  }, []);

  return (
     <Provider store={store}>
       <NavBar/>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/tentangKami" element={<TentangKami />} />
          <Route path="/perlindungan" element={<Perlindungan />} />
          <Route path="/undianBerhadiah" element={<UndianBerhadiah />} />
          <Route path="/peluangBisnis" element={<PeluangBisnis />} />
          <Route path="/kontakKami" element={<KontakKami />} />
          <Route path="/signup" element={<RegisterPage />} />
          <Route path="/signup/:id" element={<RegisterPage />} />
        </Routes>
        <Footer/>
     </Provider>
  );
}

export default App;
