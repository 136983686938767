import React from 'react';
import "./footer.css";
import { useState, useEffect } from 'react';
import nextArrow from "../../images/next_arrow.svg";

const Footer = () =>{
	const [smallStyle, setSmallStyle] = useState(false);

	useEffect(()=>{

		window.addEventListener('resize', (event)=>{
			let screenWidth = document.documentElement.clientWidth;

			if (screenWidth <= 1200)
			{
				setSmallStyle(true)
			}
			else
			{
				setSmallStyle(false);
			}
		});

	}, [smallStyle])

	
	//On initial window load
	useEffect(()=>{
		
		let screenWidth = document.documentElement.clientWidth;

		if (screenWidth <= 1200)
		{
			setSmallStyle(true)
		}
		else
		{
			setSmallStyle(false);
		}

	}, [smallStyle])

	const GoToUrl=(url) =>{
  		window.open(url, "_blank");
  	}

  	return(
  		<div className="FooterTop">
			<div className={!smallStyle ? "FooterInformationInner" : "FooterInformationInnerSmall"}>
				<h1>Hanya Rp. 0 + Rp 100.000 BIAYA ADMINISTRASI anda memperoleh:</h1>
				<ul>
					<li>1 tahun proteksi asuransi jiwa;</li>
					<li>CKU Tokens untuk kesempatan memenangkan imbalan uang tunai instan;</li>
					<li>Sharing Reward / Peluang bisnis</li>
				</ul>

				<div className={!smallStyle ? "FooterRegisterNowButton" : "FooterRegisterNowButtonSmall"} onClick={()=>{GoToUrl("https://forms.gle/CKpg4GnEWxRd49CY6")}}>
					<div className="FooterRegisterNowButtonInner"><p>DAFTAR</p></div>
					<div className="FooterRegisterNowButtonInnerEnd"><img src={nextArrow}/></div>
				</div>
			</div>
		</div>
  	)
}

export default Footer;