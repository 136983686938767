import React from 'react'
import './peluangBisnis.css';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

import luckycatLogo from "../../images/luckycat88_black_text.png";
import duotrxLogo from "../../images/Duotrx_logo.png";

const PeluangBisnis = () => {
	const [smallStyle, setSmallStyle] = useState(false);

	useEffect(()=>{

		window.addEventListener('resize', (event)=>{
			let screenWidth = document.documentElement.clientWidth;

			if (screenWidth <= 1200)
			{
				setSmallStyle(true)
			}
			else
			{
				setSmallStyle(false);
			}
		});

	}, [smallStyle])

	
	//On initial window load
	useEffect(()=>{
		
		let screenWidth = document.documentElement.clientWidth;

		if (screenWidth <= 1200)
		{
			setSmallStyle(true)
		}
		else
		{
			setSmallStyle(false);
		}

	}, [smallStyle])


	const LuckycatContent = () =>{
		return(
			<div className = {!smallStyle ? "luckyCatColumn" : "AjakMainSmallLuckyCat"}>
				<h1>Peroleh 5% CKU</h1>
				<p>dari jumlah total yang dipertaruhkan mitra anda di luckycat88.com</p>
				<div className="AjakMainSeperator"></div>
				<h1>Peroleh 50% CASH</h1>
				<p>dari jumah total yang dimenangkan mitra anda di luckycat88.com</p>
				<p>Setiap tiket yang dipertaruhkan, Rp 5.000 akan ditambahkan ke wadah hadiah menang Lucky Cat 88. Lalu jumlah uang yang terkumpul dibagi diatara pemenang :</p>
				<p>40% dari jumlah keseluruhan - Pemenang Pertama</p>
				<p>20% dari jumlah keseluruhan - Pemenang Kedua</p>
				<p>40% dari jumlah keseluruhan - Pemenang Ketiga</p>
				<p><i>Lihat <a href="https://luckycat88.com">Lucycat88.com</a> untuk mengetahui jumlah cash terkini di dalam wadah.</i></p>
				<div className="AjakMainSeperator"></div>
				<br/>
			</div>
		)
	}

	const DuotrxContent = () =>{
		return(
			<div>
				<h1>Peroleh 1% CKU</h1>
				<p>dari jumlah total yang dipertaruhkan mitra anda di Duotrx.com</p>
				<div className="AjakMainSeperator"></div>
				<h1>Peroleh 50% Cash</h1>
				<p>jika mitra anda bermain dan menang di Duotrx.com</p>
				<br/>
				<p>Duotrx.com & Chevalier of Traders menyediakan hadiah :</p>
				<p>Pemenang putaran pertama dapat Rp. 8.000.000</p>
				<p>(anda dapat Rp. 4.000.000)</p>
				<p>Pemenang putaran kedua dapat Rp. 80.000.000</p>
				<p>(anda dapat Rp. 40.000.000)</p>
				<p>Pemenang putaran ketiga dapat - Rp. 800.000.000</p>
				<p>(anda dapat Rp. 400.000.000)</p>
				<div className="AjakMainSeperator"></div>
				<br/>
			</div>
		)
	}
	
	return (
		<div className={!smallStyle ? "PeluangBisnisTop" : "PeluangBisnisTopSmall"}>
			<div className={!smallStyle ? "PeluangBisnisCover" : "PeluangBisnisCoverSmall"}>
				<div className={!smallStyle ? "PeluangBisnisCoverText" : "PeluangBisnisCoverTextSmall"}>
					<h2>Main Game ….. Dapat BONUS</h2>
					<h2>Ajak Teman ….. Dapat BONUS LEBIH</h2>
					<h2>Teman anda Menang ….. Dapat BONUS LEBIH BESAR</h2>
				</div>
			</div>
			<div className={!smallStyle ? "AjakMainTop" : "AjakMainTopSmall"}>
				<div className="AjakMainInner">
					<h1>Ajak Main ….. Dapat Reward</h1>
					<p>Ketika anda mengajak keluarga dan teman-teman bermain Game, anda akan memperoleh tambahan CKU Token dari kami, Dimana anda dapat mainkan dan menangkan uang tunai.</p>
				</div>
				{!smallStyle ? <table className="AjakMainTable">
					<tr>
						<td className="luckyCatColumn">
							<img src={luckycatLogo}/>
						</td>
						<td className="duoTrxColumn">
							<img src={duotrxLogo}/>
						</td>
					</tr>
					<tr>
						<td className="luckyCatColumn">
							<LuckycatContent/>
						</td>
						<td  className="duoTrxColumn">
							<DuotrxContent/>
						</td>
					</tr>
				</table> : 

				<div>
					<div className="AjakMainSmallLuckyCat">
						<img src={luckycatLogo}/>	
						<LuckycatContent/>
					</div>
					<div className="AjakMainSmallDuotrx">
						<img src={duotrxLogo}/>
						<DuotrxContent/>
					</div>
				</div>
				}
			</div>

			<div className={!smallStyle ? "AjakMainTop" : "AjakMainTopSmall"}>
				<div className="AjakMainInner">
					<h1>
						Referral
					</h1>
					<p>
						Anda hanya perlu membuka akun dengan Luckycat88.com & Duotrx.com dan berbagi kode referral anda dengan keluarga dan teman-teman untuk mendapatkan bonus token CKU.
					</p>
				</div>
			</div>

		</div>
	)
}

export default PeluangBisnis;