import React from 'react'
import './tentangKami.css';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

//images
import conflict from "../../images/family-conflict.jpg";

const TentangKami = () => {
	const [smallStyle, setSmallStyle] = useState(false);


	useEffect(()=>{

		window.addEventListener('resize', (event)=>{
			let screenWidth = document.documentElement.clientWidth;

			if (screenWidth <= 1200)
			{
				setSmallStyle(true)
			}
			else
			{
				setSmallStyle(false);
			}
		});

	}, [smallStyle])

	
	//On initial window load
	useEffect(()=>{
		
		let screenWidth = document.documentElement.clientWidth;

		if (screenWidth <= 1200)
		{
			setSmallStyle(true)
		}
		else
		{
			setSmallStyle(false);
		}

	}, [smallStyle])


	return(
		<div className={!smallStyle ? "TentangKamiTop" : "TentangKamiTopSmall"}>
			<div className={!smallStyle ? "TentangKamiCover" : "TentangKamiCoverSmall"}>
				<div className={!smallStyle ? "TentangKamiColumRight" : ""}>
					<h1>ASURANSI GRATIS,</h1>
					<h2>jangan sampai ketinggalan</h2>
				</div>
			</div>

			<div className="tentangKamiIntro">
				<h2>Tentang Kami</h2>
				<p>Perlindungan Asuransi Gratis.</p>
				<p>Premi Asuransi dapat teramat mahal sehingga banyak orang kesulitan dalam memilih antara bergabung dengan asuransi atau memenuhi kebutuhan hidup.</p>
				<p>95% penduduk Indonesia tidak memiliki perlindugan asuransi, sehingga kami PT. Peaceful Living Indonesia bersama dengan Azpro memasarkan Asuransi Jiwa dari Allianz Syariah yang bertujuan menyediakan asuransi yang Gratis untuk penduduk Indonesia. Sebagai bentuk apresiasi kami atas recomendasi baik anda.</p>
				<p>Waktu anda merekomendasikan keluarga dan sahabat tentang Peaceful Living, anda sebenarnya telah membantu mereka melindungi hidup mereka dengan hanya membayar Administrasi Fee sebesar Rp. 100.000. Kami dapat menawarkan Asuransi 0 rupiah karena Perusahaan kami menggabungkan Perlindungan Asuransi anda dengan produk-produk dan jasa dari sponsor kami. sehingga mereka dapat mengimbangi dan menghapus Premi Asuransi anda.</p>
			</div>

			<div className="kenapaPerluTop">
				<div className={!smallStyle ? "kenapaPerluColumn" : "kenapaPerluColumnSmall"}>
					<img src={conflict}/>
				</div>

				<div className={!smallStyle ? "kenapaPerluColumn" : "kenapaPerluColumnSmall"}>
					<h2>Kenapa Perlu</h2>
					<p>Tidak seperti BPJS yang hanya melindungi kesehatan hari2, Peaceful Living melindungi keadaan yang tidak pernah kita duga di kehidupan kita.</p>
					<p>Santunan Uang Pertanggungan kepada keluarga/ ahli waris jika tertanggung meninggal dunia dalam masa pertanggungan.( besar nya pertanggungan dapat dilihat di halaman <a href="/perlindungan">Perlindungan</a>).</p>
					<p>Jika anda memiliki anggota keluarga yang masih bergantung dari pendapatan anda , Peaceful Living menyediakan bantuan keuangan jikalau pemegang polis meninggal dunia. Dimana santunan ini sangat melegakan anggota keluarga yang ditinggal. Misalnya untuk biaya pemakaman , membayar pajak harta warisan, pendidikan anak, dsb.</p>
					<p>Dengan kepastian keluarga anda terlindungi dan jaminan santunan keuangan, kita dapat menjalankan hidup yang tenang tanpa khawatir apa yang akan terjadi nanti pada orang yang kita cintai.</p>
					<p>Pada dasarnya Peaceful Living ada untuk mempersiapkan kita dari hal-hal yang tidak diharapkan dan memastikan keluarga/orang yang kita cintai aman secara keuangan. Apapun yang akan terjadi di kemudian hari.</p>
				</div>
			</div>
		</div>
	)
}

export default TentangKami;