import React from 'react'
import './undianBerhadiah.css';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

//images
import cat from "../../images/cat.png";
import duoTRXLogo from "../../images/Duotrx_logo.png";
import duoTRXDevice from "../../images/duotrx_device.png";

const UndianBerhadiah = () => {
	const [smallStyle, setSmallStyle] = useState(false);


	useEffect(()=>{

		window.addEventListener('resize', (event)=>{
			let screenWidth = document.documentElement.clientWidth;

			if (screenWidth <= 1200)
			{
				setSmallStyle(true)
			}
			else
			{
				setSmallStyle(false);
			}
		});

	}, [smallStyle])

	
	//On initial window load
	useEffect(()=>{
		
		let screenWidth = document.documentElement.clientWidth;

		if (screenWidth <= 1200)
		{
			setSmallStyle(true)
		}
		else
		{
			setSmallStyle(false);
		}

	}, [smallStyle])

	return (
		<div className={!smallStyle ? "undianBerhadiahTop" : "undianBerhadiahTopSmall"}>
			<div className={!smallStyle ? "undianBerhadiahCover" : "undianBerhadiahCoverSmall"}>
				<div className={!smallStyle ? "BisaMenang" : "BisaMenangSmall"}><h2>Anda juga bisa</h2> <h1>MENANG</h1></div>
			</div>

			<div className={!smallStyle ? "MainMenangkan" : "MainMenangkanSmall"}>
				<h1>Main & Menangkan</h1>
				<p>Member Peaceful Living dapat menikmati berbagai pemainan berhadiah. Kami meyediakan berbagai permainan yang berbeda bagi setiap orang. Permainan berdasarkan keberuntungan ataupun yang memerlukan keterampilan.</p>
				<p>Ketika anda bergabung dengan Peaceful Living, kami ingin memberikan kesempatan kepada anda untuk memenangkan beberapa imbalan keuangan, sebagai bentuk penghargaan kami kepada anda.</p>
			</div>

			<div className="LuckyCatSection">
				<div className="LuckycatTopSection">
					<div className={!smallStyle ? "LuckyCatSectionColumn" : "LuckyCatSectionColumnSmall"}>
						<h1>Lucky Cat 88</h1>
						<img src={cat}/>
					</div>

					<div className={!smallStyle ? "LuckyCatSectionColumn" : "LuckyCatSectionColumnSmall"}>
						<p>Luckycat88.com adalah permainan keberuntugan Dimana peserta bebas memilih nomor keberuntungan mereka. Dan jika nomor yang mereka pilih ter-undi/keluar, maka akan mendapatkan hadiah.</p>
						<p>Syarat menang, peserta harus :</p>
						<p><b>Pemenang Pertama -</b></p>
						<p>Memilih 5 nomor yang sama dari 7 nomor yang keluar, Dimana 5 nomor itu harus termasuk nomor extra.</p>
						<p><b>Pemenang Kedua -</b></p>
						<p>Memilih 4 nomor yang sama dari 6 nomor yang pertama keluar.</p>
						<p><b>Pemenang Ketiga -</b></p>
						<p>Memilih 4 nomor yang sama dari 7 nomor yang keluar, Dimana 4 nomor itu harus termasuk nomor extra.</p>

						<p>Untuk menjamin transparansi, nomor yang keluar/ di undi di luckycat88.com, mangacu/mengikuti siaran langsun dari Hong Kong Mark 6, yang di operasikan oleh Hong Kong Jockey Club.</p>
						<p><a href="https://luckycat88.com" target="_blank">Klik disini untuk coba</a></p>
					</div>
				</div>
				<div className={!smallStyle ? "LuckyCatBottomSection" : "LuckyCatBottomSectionSmall"}>
					<p><u>Cara Main</u></p>
					<p>Peserta harus memilih 6 nomor dari Kumpulan nomor 1 sampai 49. Undian diadakan pada hari Selasa, Kamis, dan Sabtu/Minggu.</p>
					<p>Waktu undian Lucky Cat 88 dilaksanakan, 6 nomor (diantara 1-49) di keluarkan secara acak. Dan 1 nomor extra dikeluarkan dari sisa 43 nomor di Kumpulan itu. Total 7 nomor in mewakili garis kemenangan.</p>
					<p>Hadiah imbalan diberikan Ketika nomor yang anda pilih pada Lucky Cat 88 cocok dengan nomor yang keluar di Mark 6. kunjungi luckycat88.com untuk reward terbaru.</p>

					<p><u>Pembagian Dana</u></p>
					<p> Setiap member yang baru bergabung dengan Peaceful Living, Rp. 20.000 ditambahkan ke wadah menang Lucky Cat 88. Lalu jumlah uang yang terkumpul dibagi diantara pemenang :</p>
					<p>Hadiah Pertama : 40% dari jumlah keseluruhan wadah menang dibagi rata diantara permenang pertama ;</p>
					<p>Hadiah Kedua : 20% dari jumlah keseluruhan wadah menang dibagi rata diantara permenang kedua ;</p>
					<p>Hadiah Ketiga : 40% dari jumlah keseluruhan wadah menang dibagi rata diantara permenang ketiga ;</p>

					<p><u>Biaya</u></p>
					<div className="LuckyCatWinTable">
						<div className={!smallStyle ? "LuckyCatWinTableHeader" : "LuckyCatWinTableHeaderSmall"}>
							<section>Nomor</section>
							<section>Tiket</section>
							<section>Jumlah CKU</section>
						</div>
						<div className={!smallStyle ? "LuckyCatWinTableRow" : "LuckyCatWinTableRowSmall"}>
							<section>Pilih 6 nomor</section>
							<section>1 tiket</section>
							<section>20 CKU</section>
						</div>
						<div className={!smallStyle ? "LuckyCatWinTableRow" : "LuckyCatWinTableRowSmall"}>
							<section>Pilih 7 nomor</section>
							<section>7 tiket</section>
							<section>140 CKU</section>
						</div>
						<div className={!smallStyle ? "LuckyCatWinTableRow" : "LuckyCatWinTableRowSmall"}>
							<section>Pilih 8 nomor</section>
							<section>28 tiket</section>
							<section>560 CKU</section>
						</div>
						<div className={!smallStyle ? "LuckyCatWinTableRow" : "LuckyCatWinTableRowSmall"}>
							<section>Pilih 9 nomor</section>
							<section>84 tiket</section>
							<section>1.680 CKU</section>
						</div>
						<div className={!smallStyle ? "LuckyCatWinTableRow" : "LuckyCatWinTableRowSmall"}>
							<section>Pilih 10 nomor</section>
							<section>210 tiket</section>
							<section>4.200 CKU</section>
						</div>
						<div className={!smallStyle ? "LuckyCatWinTableRow" : "LuckyCatWinTableRowSmall"}>
							<section>Pilih 11 nomor</section>
							<section>462 tiket</section>
							<section>9.240 CKU</section>
						</div>

					</div>
				</div>
			</div>

			<div className="DuoTrxSection">
				<div className="DuoTrxIntro">
					<div className={!smallStyle ? "DuoTrxIntroColumn" : "DuoTrxIntroColumnSmall"}>
						<h1>Duotrx</h1>
						<img src={duoTRXDevice}/>
					</div>
					<div className={!smallStyle ? "DuoTrxIntroColumn" : "DuoTrxIntroColumnSmall"}>
						<p>Duotrx.com adalah wadah permainan simulasi trading Dimana peserta berkesempatan mengikuti turnamen Trading - Chevalier of Traders. Peserta berkesempatan memenangkan :</p>
						<p>Putaran 1 - Rp. 8.000.000</p>
						<p>Putaran 2 - Rp. 80.000.000</p>
						<p>Putaran 3 - Rp. 800.000.000</p>
						<p>Untuk menang setiap peserta harus mencapai jumlah tertentu dari keuntungan trading setiap putaran :</p>
						<p>Putaran 1 - 5.000 CKU Profit</p>
						<p>Putaran 2 - 40.000 CKU Profit</p>
						<p>Putaran 3 - 100.000 CKU Profit</p>
						<p>Untuk menjamin transparansi, Duotrx menyediakan link ke tradingview.com, sehingga peserta dapat mem verifikasi angka angka nya secara real time/ dalam waktu nyata.</p>
						<p><a href="https://duotrx.com" target="_blank">Klik disini untuk coba</a></p>
					</div>
				</div>


				<div className={!smallStyle ? "DuoTrxBrand" : "DuoTrxBrandSmall"}>
					<img src={duoTRXLogo}/>
				</div>
				<div className={!smallStyle ? "DuoTrxHeader" : "DuoTrxHeaderSmall"}>
					<h1>Chevalier of Traders</h1>
					<h2>Peraturan & Regulasi Chevalier Trader</h2>
				</div>

				<div className={!smallStyle ? "DuoTrxExplanationSection" : "DuoTrxExplanationSectionSmall"}>
					<p>“Chevalier of Traders” adalah trading turnamen yang menawarkan hadiah kepada peserta yang mampu mencapai putaran tertentu :</p>

					<table>
						<tr>
							<th>
								Putaran
							</th>
							<th>
								Skor
							</th>
							<th>
								Maximum Number of Winners
							</th>
							<th>
								Hadiah Pemenang
							</th>
						</tr>
						<tr>
							<td>
								1
							</td>
							<td>
								CKU 5.000 Poin
							</td>
							<td>
								Tidak ada batas jumlah pemenang
							</td>
							<td>
								Rp. 8.000.000
							</td>
						</tr>
						<tr>
							<td>
								2
							</td>
							<td>
								CKU 40.000 Poin
							</td>
							<td>
								100 Pemenang
							</td>
							<td>
								Rp. 80.000.000
							</td>
						</tr>
						<tr>
							<td>
								3
							</td>
							<td>
								CKU 100.000 Poin
							</td>
							<td>
								1 Pemenang
							</td>
							<td>
								Rp. 800.000.000
							</td>
						</tr>
					</table>

					<p>Syarat dan ketentuan untuk seluruh peserta</p>
					<br/>
					<p><u>Jumlah CKU yang di pertaruhkan</u></p>
					<p>1a.  Jumlah maksimal CKU yang di di ijinkan / trade :</p>
					<ul>
						<li>Putaran 1 = Maksimal CKU 1.000 / trade</li>
						<li>Putaran 2 = Maksimal CKU 5.000 / trade</li>
						<li>Putaran 3 = Maksimal CKU 10.000 / trade</li>
					</ul>


					<p>1b.   Jika jumlah yang dipertaruhkan melebihi batas maximum, maka skor trading tidak akan dihitung sebagai nilai akhir;</p>
					<p>1c. Poin yang diperoleh dari CKU yang dipertaruhkan berdasarkan prediksi tren pasar yang tepat akan ditambahkan ke skor keseluruhan peserta;</p>
					<p>1d. CKU yang dipertaruhkan terhadap predikisi tren pasar yang salah akan dikurangi dalam skor keseluruhan perserta.</p>

					<p><u>Naik Satu Putaran</u></p>
					<p>2a. Peserta harus memenangkan putaran ke satu sebelum perserta dapat melanjutkan ke putaran ke dua, dan peserta harus memenangkan putaran ke dua sebelum dapat melanjutkan ke putaran ke tiga.</p>

					<p><u>Penilaian Waktu Mulai dan Waktu Berakhir</u></p>
					<p>3a. Putaran dimulai pada 00:01 WIB dan berakhir pada 23.59 WIB pada hari yang sama;</p>
					<p>3b. Poin CKU yang diperoleh dari hari perdangangan sebelumnya tidak dapat digunakan sebagai akumulasi skor keseluruhan peserta;</p>
					<p>3c. Hanya poin yang dicetak dalam periode 24 jam dari 00:01 WIB hinga 23:59 WIB pada hari yang sama yang dapat diakumulasikan sebagai bagian dari skor CKU perserta.</p>

					<p><u>Klaim Kemenangan Anda</u></p>
					<p>4a. Hadiah Rupiah yang ditawarkan kepada perserta pemenang disediakan oleh PT. Peaceful Living Indonesia</p>
					<p>4b. Perserta yang menang harus mengirimkan email ke : kingoftraders@duotrx.com untuk mengklaim hadiahnya dengan informasi sebagai berikut :</p>
					<ol>
						<li>Alamat email terdaftar duotrx.com peserta;</li>
						<li>Foto berwarna dari peserta yang memegang tanda “Saya adalah trader top Duotrx”;</li>
						<li>Data bank untuk menerima hadiah (Nama Bank , Nomor Rekening Bank, Nama Rekening Bank)</li>
					</ol>

					<p>4c. Peserta pemenang yang BERHASIL DIVERIFIKASI akan menerima hadiah Rupiah. Dalam waktu 24 jam kecuali hari Sabtu, Minggu, dan hari Libur Nasional.</p>
					<p>4d. Semua peserta pemenang setuju untuk diposting di halaman media social Duotrx.</p>
				</div>
				<div className={!smallStyle ? "DuoTrxFooter" : "DuoTrxFooterSmall"}>
					<h3><u>PERINGATAN</u></h3>
					<p>CKU hanyalah token permainan yang dapat digunakan oleh peserta di platform Duotrx.com dan tidak dapat ditukar/ dikembalikan/ dijual ke Duotrx dan
Perusahaan terdaftar afiliasinya untuk rabat/pengembalian/ keuntungan financial apa pun.Peserta mendapatkan CKU hanya untuk tujuan bermain Duotrx.com. Satu
-satunya imbalan finansial yang ditawarkan kepada peserta adalah hadiah yang ditawarkan kepada pemenang turnamen “Chevalier of Traders”, dan tidak
lebih. Duotrx dan perusahaan afiliasinya mempunyai hak Utuh untuk mengubah syarat, ketentuan, dan aturan apa pun terkait duotrx.com, dan turnamen
Chevalier of Traders.</p>
				</div>
			</div>
		</div>
	)
}

export default UndianBerhadiah;