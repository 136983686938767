import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import navbarReducer from './slices/navbarSlice';
import playerReducer from './slices/playerSlice';
import commonReducer from './slices/commonSlice';
import walletReducer from './slices/walletSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    navbar: navbarReducer,
    player: playerReducer,
    common: commonReducer,
    wallet: walletReducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
});
